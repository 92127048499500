/*jslint browser: true, nomen: true */
/*global $ */

var datepicker = $.fn.datepicker.noConflict(); // return $.fn.datepicker to previously assigned value
$.fn.bootstrapDP = datepicker;                 // give $().bootstrapDP the bootstrap-datepicker functionality

function onYouTubeIframeAPIReady() {
    console.log('Youtube api ready');
    (new YouTubeIframeAPI('[data-youtube]')).build();
};

function GoogleMapsApi() {
    console.log('google map api ready');
    (new GoogleMap('map-div')).init();
    (new Geocode($('[data-geocode]'))).init();
}

function PageTimer() {
    var self = this;
    this.getLoadTime = function () {
        var now = new Date().getTime();
        // Get the performance object
        window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
        var timing = performance.timing || {};

        var ms = now - timing.navigationStart;

        return Math.round(ms / 10) / 100;
    };

    this.logToConsole = function () {
        $(window).on('load', function () {
            console && console.info && console.info("Loaded in " + self.getLoadTime() + 's');
        });
    };

    this.append = function (holder) {
        $(window).on('load', function () {
            holder.text(' | LT: ' + self.getLoadTime() + 's');
        });
    };
}

function ExternalLinkHandler() {
    var self = this;
    var hostname = document.location.hostname;

    this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

    this.addTargetAttribute = function (context) {
        context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
            var anchor = $(this);
            var href = anchor.attr('href');
            var isInternalLink = false;

            for (var i = 0; i < self.matchInternalLink.length; i++) {
                var regex = self.matchInternalLink[i];
                if (href.match(regex)) {
                  isInternalLink = true;
              }
            }

            if (!isInternalLink) {
                anchor.attr('target', '_blank').addClass('external-link');
            }
        });
    };
}

function StickyFooter($wrap, $footer) {
    var $window = $(window);

    this.updateWrapperCSS = function (evt) {
        var footerHeight = $footer.outerHeight();

        $wrap.css({
            marginBottom: -1 * footerHeight,
            paddingBottom: footerHeight
        });

        if (typeof evt !== 'undefined' && evt.type === 'load') {
            $footer.removeClass('page-loading');
        }

    };

    this.bindOnResize = function () {
        $window.on('resize', this.updateWrapperCSS);
        return this;
    };

    this.init = function () {
        $window.on('load', this.updateWrapperCSS);
        return this;
    };
}

function scrollToElement(options) {
    var id, speed = 750, offset = 0, targetOffset, data, selector;

    selector = options.selector;
    speed = options.speed ? options.speed : speed;
    offset = options.offset ? options.offset : offset;

    targetOffset = $(selector).offset().top - offset;

    $('html,body').animate({scrollTop: targetOffset}, speed, function () {});
}

function UIBindings() {
    this.selectize = function (selector, options) {
        var settings =  {
            onChange: function (value) {
                console.log(selector, value);
            }
        };

        if (options){
            settings = $.extend({}, settings, options)
        }

        $(selector).selectize(settings);
    };

    this.bindMagnificPopup = function () {

        $('[data-magnific-ajax-form]').magnificPopup({
            type: 'ajax',
            alignTop: true,
            overflowY: 'scroll',
            modal: true,
            settings: {cache:false, async:false},
            callbacks: {
                ajaxContentAdded: function () {
                    var magnificPopup = this;

                    var $mainEl = $(this.st.mainEl)
                    var forms =  $(this.content).find('form');
                   
                    if (forms.exists()) {
                        var dataField = $mainEl.data('field');
                        var mainElForm = $mainEl.closest('form');
                        var selectizeField = mainElForm.find('[name="'+ dataField +'"]');

                        forms.find('[data-mh]').matchHeight({ property: 'min-height' });

                        new Wygwam("cf_general_longer", "1", false);  //Todo: optimise;

                        (new formBindings(forms, {popupInstance: magnificPopup, selectizeInstance: selectizeField[0].selectize})).validate().bindEvents();
                    }
                }
            }
        });

        $('[data-magnific-image]').magnificPopup({
            type: 'image'
        });
    };

   

    this.bindSharing = function(){
        $("[data-share-default]").each(function () {

            var shareHandler = new ShareHandler($(this), true);
            shareHandler.appendFacebook();
            shareHandler.appendTwitter();
        });
    };

    this.bindEvents = function(){
        var tableHeaders = {
            '3': 'Sector',
            '2': 'Province'
        };

        var dataTable = $('[data-table]').DataTable({
            "dom": '<"toolbar">frtip',
            pagingType: "simple",
            ordering: false,
            lengthChange: false,
            "columnDefs": [
                {
                    "targets": [2,3],
                    "visible": false
                }
            ],
            initComplete: function () {
                this.api().columns([2,3]).every(function () {
                    var column = this;
                    var select = $('<select class="form-control input-sm"><option value="">Select ' + tableHeaders[column.index()] +'</option></select>')
                        .appendTo($('div.toolbar'))
                        .on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            column
                                .search(val ? '^' + val + '$' : '', true, false)
                                .draw();
                        });

                    select.wrap('<span class="d-ib select select-default input-icon right">');
                    
                    column.data().unique().sort().each(function (d, j) {
                        if (d.trim()) {
                            select.append('<option value="' + d + '">' + d + '</option>');
                        }
                        
                    });
                });
            }
        });

        $('[data-ga-send-event]').on('click', function (evt) {
            // evt.preventDefault();
            var data = $(this).data('ga-send-event');

            console.log(typeof data);
            console.log(data.eventCategory, data.eventAction, data.eventLabel, data);

            ga('send', 'event', data);
        });

        $('[href="#disabled"]').on('click', function(evt){
            evt.preventDefault();
        });


        $('[name*="question"]').on('change', function(){
            var val = this.value;

            var $answer = $(this).closest('[data-answer]');
            var $correct = $answer.siblings().filter(function(){
                return $(this).data('class') === 'success';
            });

            $answer.siblings().removeClass('success danger').find('input').attr('disabled', true);

            console.log($answer, $correct);

            if ($answer !== $correct) {
                $correct.addClass('warning');
            }

            $answer.addClass($answer.data('class'));
        })

        $('table').each(function(){
            var table = $(this);

            if (table.inlineStyle('border') === 'none' || table.inlineStyle('border-style') === 'none') {
                table.removeAttr('style').addClass('table-borderless');
            }

            if (table.inlineStyle('width')) {
                table.removeAttr('style');
            }
            table.addClass('table');
        })

        $('[data-minheight]').matchHeight({ property: 'min-height' });


        $('#accordion span.checked-icon').each(function () {
            var panel = $(this).closest('.panel');
            panel.find('.collapse').addClass('in');
            panel.find('[data-toggle="collapse"]').removeClass('collapsed');
        });

        $('[data-toggle="tooltip"]').tooltip(),
        $('[data-toggle="popover"]').popover();
        

        $('#top-link').topLink({
            min: 400,
            fadeSpeed: 500
        }).on('click','a', function (e) {
            e.preventDefault();
            scrollToElement({selector: "#header", speed: 300});
        });

        $('.my-affix').customAffix();
        $('.share-this').customAffix();
    };

    this.bindToc = function() {
        var tocList = $('#toc');
        var tocContext = $('[data-toc-content]');

        if (!tocList.exists()) {
            return;
        }

        tocList.tocify({
            context: tocContext,
            selectors: 'h2,h3',
            history: true,
            ignoreSelector: '.panel-title, .tab-content h2, .tab-content h3',
            theme: 'none',
            extendPage: false,
            highlightDefault: true,
            highlightOffset: 80
        });


        $(document).on('click', '#toc [data-unique]', function(){
            console.log($(this).closest('ul').prev('li'));
        })
    };

    this.bindSliders = function () {
        var mq = false,
        slickSlideshow = $('.slick-slideshow'),
        slidersResize = function (mq) {
            if (slickSlideshow.exists()) {
                var matchHeightElements = slickSlideshow.find('[data-mh]');
                if (mq.matches) {
                    matchHeightElements.matchHeight({remove: true});
                } else {
                    matchHeightElements.matchHeight(true);
                }
            }
        };

        if (typeof matchMedia !== 'undefined') {
            mq = window.matchMedia("(min-width: 768px)");
            mq.addListener(slidersResize);
            slidersResize(mq);
        }

        slickSlideshow
        .on('beforeChange', function (event, slick, currentIndex, nextIndex) {
            // check youtube video player button
            var curSlider = $(slick.$slides[currentIndex]),
            ytPlayBtn = curSlider.find('.slide-player'),
            ytPlayer = ytPlayBtn.data('player');

            if (ytPlayBtn.exists() && ytPlayer) {
                var playerState = ytPlayer.getPlayerState();
                if (playerState === 1) {
                    ytPlayer.pauseVideo();
                }
            }
        })
        .slick({
            dots: true,
            arrows: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: true
        });

        $('.slick-carousel')
        .on('beforeChange', function (event, slick) {
                    // Update match height on next elements
                    $(slick.$slider[0]).find('[data-mh]').matchHeight(true); // need a better way
                })
        .slick({
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            ]
        });


        var blogSlider = $('.slider-for'),
        blogSlideElements = $('.slick-nav-item');
        blogSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    speed: 100
                }
            },
            {
                breakpoint: 768,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: 'null'
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true
                }
            }
            ]
        });

        var carouselPageNavItems = $('[data-slick-carousel-page-nav] [data-index]');

        $('[data-slick-carousel-page]').on('beforeChange', function (event, slick, currentIndex, nextIndex) {
            // check youtube video player button
            var curSlider = $(slick.$slides[currentIndex]),
            ytPlayBtn = curSlider.find('.slide-player'),
            ytPlayer = ytPlayBtn.data('player');

            if (ytPlayBtn.exists() && ytPlayer) {
                var playerState = ytPlayer.getPlayerState();
                if (playerState === 1) {
                    ytPlayer.pauseVideo();
                }
            }

            setActiveNavItem(carouselPageNavItems, (nextIndex + 1));

        })
        .on('init', function (event, slick) {
            setActiveNavItem(carouselPageNavItems, (slick.currentSlide + 1));
        })
        .slick({
            dots: false,
            arrows: false,
            fade: true,
            autoplay: false,
            pauseOnHover: true
        });

        
        carouselPageNavItems.on('click', function (e) {
          e.preventDefault();
          var index = $(this).data('index');

          setActiveNavItem(carouselPageNavItems, index);

          $('[data-slick-carousel-page]').slick('slickGoTo', index - 1, true);
        });



        blogSlideElements.on('click', function () {
            var el = $(this);
            var index = el.data('index');

            setActiveNavItem(blogSlideElements, index);

            blogSlider.slick('slickGoTo', index - 1, true);
        });

        $('.page-alt-slideshow').cycle();


        $('[data-slick-carousel-default]').slick({
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false

        });

        function setActiveNavItem(elements, index){
            elements.removeClass('slick-page-nav-active').filter(function(){
            return $(this).data('index') === index;
          }).addClass('slick-page-nav-active');
        }
    };
}

$.fn.exists = function () {
    return $(this).length > 0;
};

$.fn.inlineStyle = function (prop) {
    return this.prop("style")[$.camelCase(prop)];
};

$.fn.customAffix = function (options) {
    var that = $(this);
    if (!that.length) {
        return;
    }
    var settings = $.extend({
        scrollTopMinSelector: that.parent(),
        scrollTopMaxSelector: '#footer',
        offset: 20
    }, options),
    _window = $(window),
    positionElement = function () {

        var element, parentElement,
        scrollTopMaxElement,
        scrollTopMinElement,
        scrollTop,
        elementHeight,
        elementTop,
        documentHeight, scrollTopMax, scrollBottomMax, scrollDifference, top;

        element = $(this);
        parentElement = that.parent();
        scrollTopMaxElement = $(settings.scrollTopMaxSelector);
        scrollTopMinElement = $(settings.scrollTopMinSelector);
        scrollTop = _window.scrollTop();
        elementHeight = element.outerHeight();
            elementTop = scrollTopMinElement.offset().top; // + element.prevAll().outerHeight();
            documentHeight = $(document).outerHeight();
            scrollTopMax = scrollTopMaxElement.outerHeight();
            scrollBottomMax = documentHeight - ( elementHeight + scrollTopMax);
            scrollDifference = scrollBottomMax - scrollTop;

            element.removeClass('sticky static');

            if (scrollTop < elementTop - settings.offset) {
                element.removeClass('sticky').addClass('static');
            } else if (scrollTop < scrollBottomMax) {
                element.addClass('sticky').css({'top': settings.offset, 'width': parentElement.width()});
            } else {
                element.addClass('sticky').css({'top': scrollDifference, 'width': parentElement.width()});
            }
        };

        return this.each(function () {
            _window.on('scroll scrollstop resize', $.proxy(positionElement, this));
            $.proxy(positionElement, this)();
        });
    };

// scroll to top og page
$.fn.topLink = function (settings) {
    settings = $.extend({
        min: 1,
        fadeSpeed: 200,
        ieOffset: 50
    }, settings);

    return this.each(function () {
        //listen for scroll
        var el = $(this);
        el.css('display', 'none'); //in case the user forgot

        $(window).scroll(function () {
      
            if ($(window).scrollTop() >= settings.min) {
                el.fadeIn(settings.fadeSpeed);
            }
            else {
                el.fadeOut(settings.fadeSpeed);
            }
        });
    });
};

function Geocode(elements) {
    var geoElement = $(elements);
    var geoOptions = {
        blur: false,
        geocodeAfterResult: true,
        detailsAttribute: "data-geo"
    };
    var self = this;

    this.init = function () {

        geoElement.each(function () {

            var $this = $(this);
            geoOptions.details = $this.closest('.form-group');

            $this.geocomplete(geoOptions);
            $this.attr('placeholder', '');
        })
        .bind('geocode:result', function (evt, results) {
            var $this = $(evt.target);

            var details = $this.closest('.form-group');
            var lng = results.geometry.location.lng();
            var lat = results.geometry.location.lat();
            var address = results.formatted_address;
            var placeID = results.place_id;

            var jsonArray = {latitude: lat, longitude:lng, zoom:12, formatted_address: address, place_id: placeID };

            details.find('[data-geo-json]').val(JSON.stringify(jsonArray));
            console.log(JSON.stringify(jsonArray), results, results.geometry.location.lat(), results.geometry.location.lng());

        });
    };


    this.getAddressComponents = function (obj) {
        var components = obj.address_components;
        var addressComponents = {};

        for (var i = components.length - 1; i >= 0; i--) {

            for (var b = 0; b < components[i].types.length; b++) {
                var type = components[i].types[b];

                switch (type) {
                    case 'country':
                    addressComponents['country'] = components[i];
                    addressComponents['countryCode'] = components[i].short_name;
                    break;
                    case 'locality':
                    addressComponents['locality'] = components[i];
                    break;
                    case 'sublocality':
                    addressComponents['sublocality'] = components[i];
                    break;
                    case 'administrative_area_level_1':
                    addressComponents['state'] = components[i];
                    break;
                }
            }

        }

        return addressComponents;
    };
}

function MatrixBlocks(container) {
    var $container = $(container);
    var blocks = $container.find('[data-block]');
    var addBtn = $container.find('[data-add]');
    var self = this;
    var index = 0;
    var templates;

    this.bindEvents = function () {
        var template;

        addBtn.on('click', function () {
            var templateId = $(this).data('add');
            template = $(templateId).html();
            template = $($.trim(template));
            blocks.append(template);
            self.updateIndexes();

            $container.trigger('mx.new.block', template);

        });

        self.bindRemoveTemplate();

    };

    this.bindRemoveTemplate = function () {
        $container.on('click', '[data-remove]', function () {
            var block = $(this).closest('.matrix-block');

            $container.trigger('mx.del.block', block);
            block.hide();
            self.updateIndexes();

            return false;
        });
    };

    this.updateIndexes = function () {
        templates = blocks.find('.matrix-block:visible');

        $.each(templates, function (i, template) {
            var $template = $(template);
            var inputs = $template.find('[data-name]');

            $template.attr('data-index', i);

            inputs.each(function () {
                var $el = $(this);
                var value = $el.data('value');
                var name = $el.data('name');

                if (name) {
                    $el.attr('name', name.replace(/\%/g, i));
                }

                if (value) {
                    $el.attr('value', value.replace(/\%/g, i));
                }
            });
        })
    };
}

function formBindings(selector, options) {
    var forms = $(selector);
    var options = options ? options : {};
    var settings = $.extend({popupInstance: null, selectizeInstance: null}, options)

    this.addValidators = function(){

        Parsley.addValidator('requiredField', {
            requirementType: 'string',
            validate: function (_value, requirement, instance) {

                var $element = $(instance.$element);

                var input = $element.find('[name="' + requirement +'"]:checked, [name="' + requirement +'"]');
                var inputVal = '';
                var isValid = false;
                var inputChecked = false;

                if(input.is(':radio')) {

                    isValid = input.filter(function(){
                        return $(this).is(':checked');
                    }).exists();
                }  else {
                    inputVal = input.val();
                    isValid = !!$.trim(inputVal);
                }

                console.log($element.data('parsleyGroup'), 'isValid', isValid );

                return isValid; // No section is filled, this validation fails
            }
        });

        Parsley.addValidator('urlstrict', {
            requirementType: 'string',
            validate: function(value, requirement, instance) {
                var regExp = /^(https?|s?ftp|git):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

                return '' !== value ? regExp.test( value ) : false;
            }
        })


        return this;
    };

    this.validate = function(){
        if (forms.exists()) {

            forms.parsley({
                inputs: Parsley.options.inputs + ',[data-parsley-recaptcha],[data-parsley-urlstrict], [data-parsley-honeypot], [data-parsley-required-field]:visible, [data-parsley-check-children]',
                errorClass: 'has-error',
                excluded: Parsley.options.excluded + ', input:hidden'
            });

            Parsley.on('form:submit', function (ParsleyForm) {

                var formElement = ParsleyForm.$element[0];
                var $formElement = $(formElement);
                var isAjax = $formElement.hasClass('is-ajax');
                var submitText =$formElement.find('[data-submit-text]');
                var submittingText =$formElement.find('[data-submitting-text]');
                var btn = $formElement.find('button[type="submit"]');
                var data = btn.data('ga-send-form');

                submittingText.removeClass('hide');
                submitText.hide();
                btn.prop('disabled', true);


                if(isAjax){
                    
                    ParsleyForm.validationResult = false;

                    formData = new FormData(formElement);

                    $.ajax({
                        url: '/organisation/create',
                        data: formData,
                        type: 'POST',
                        crossDomain: false,
                        cache: false,
                        processData: false,
                        contentType: false
                    }).done(function (data) {
                        /// consider reworking this
                        console.log('success', data);

                        var item = {
                            id: data.entry_id,
                            url: data.url_title,
                            name: formElement.title.value
                        };

                        var selectize = settings.selectizeInstance;

                        if (selectize) {
                            selectize.addOption(item);
                            selectize.addItem(data.entry_id); 
                        }

                        if (settings.popupInstance && settings.popupInstance.isOpen) {
                            settings.popupInstance.close();
                        }

                    
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        console.log('error', textStatus, errorThrown);
                    }).always(function () {
                        submittingText.addClass('hide');
                        submitText.show();
                        btn.prop('disabled', false);
                    });

                    return false;
                }

                if ($formElement.hasClass('subscription-box')) {
                    var honeypot = $(formElement).find('#validationfield');
                    var honeypotValue = $.trim(honeypot.val());

                    if (honeypotValue.length > 0) {
                        console.log(honeypotValue, 'Honey pot value');
                        return;
                    } else {
                            honeypot.remove();
                            // updates field names
                            var formFields = $formElement.find('[data-name]');
                            formFields.each(function (key, field) {
                                field.name = $(field).data('name');
                            });
                    }

                    if (formElement.validationField) {
                        $(formElement.validationField).remove();
                    }   
                }

                if (data) {
                    if (formElement.username) {
                        data.eventLabel = $.trim(formElement.username.value);
                    }

                    console.log(data.eventCategory, data.eventAction, data.eventLabel);
                    ga('send', 'event', data);
                }

            });
        }

        return this;
    };

    this.bindEvents = function () {

        var matrixBlocks = forms.find('[data-matrix]');

        matrixBlocks.each(function () {
            (new MatrixBlocks($(this))).bindEvents();
        });

        // selectize
        var selectOptions = {
            copyClassesToDropdown: false,
            persist: false,
            maxItems: 1,
            valueField: 'name',
            labelField: 'name',
            searchField: 'name',
            preload: 'focus',
            onInitialize: function () {
                $(this.$wrapper).addClass('single');
            },
            create: function(input) {
                return {
                    value: input,
                    name: input
                }
            },
            load: function(query, callback) {
                if (!query.length) return callback();
                $.ajax({
                    url: '/organisation/json',
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        name: encodeURIComponent(query)
                    },
                    error: function() {
                        callback();
                    },
                    success: function(res) {
                        callback(res.slice(0, 10));
                    }
                });
            }
        };

        matrixBlocks.on('mx.new.block', function (evt, target) {
            console.log('new block');
            (new UIBindings).selectize($(target).find('[data-selectize-organisation]'), selectOptions);
        });

        matrixBlocks.on('mx.del.block', function (evt, target) {
            console.log('del element');
            $(target).find('[data-update-hidden]').val('');
            $(target).find('[data-update-checkbox]').prop('check', true);
        });

        var organisationSelects = forms.find('[data-selectize-organisation]');
        var extendedSelectOptions = $.extend({}, selectOptions, { valueField: 'id', create: false, maxItems: null, plugins: ['remove_button'], closeAfterSelect: true });

        organisationSelects.each(function () {

            var organisationSelectize = $(this);
            var items = organisationSelectize.data('selectizeOrganisation');
            var initialItems = [];
            var elOptions = {};
            var maxItems = organisationSelectize.data('selectizeMaxItems');

            if (maxItems) {
                elOptions.maxItems = maxItems;
            }

            if(items) {

                $.each(items, function (key, obj) {
                    if ('id' in obj) {
                        initialItems.push(obj.id);
                    }
                });

                extendedSelectOptions.items = initialItems;
                extendedSelectOptions.options = items;
            }

            (new UIBindings).selectize($(this), $.extend({}, extendedSelectOptions, elOptions) )
        });

        var researcherSelects = forms.find('[data-selectize-researcher]');
        var extendedReseacherOptions = $.extend({}, selectOptions, { 
            valueField: 'id', 
            create: false, 
            maxItems: 1, 
            plugins: ['remove_button'], 
            closeAfterSelect: true,
            load: function(query, callback){
                if (!query.length) return callback();
                $.ajax({
                    url: '/members/json',
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        keywords: encodeURIComponent(query)
                    },
                    error: function() {
                        callback();
                    },
                    success: function(res) {
                        callback(res.slice(0, 10));
                    }
                });
            }
        });

        researcherSelects.each(function () {

            var researcherSelectize = $(this);
            var items = researcherSelectize.data('selectizeResearcher');
            var elOptions = {};
            var initialItems = [];
            var maxItems = researcherSelectize.data('selectizeMaxItems');

            if (maxItems) {
                elOptions.maxItems = maxItems;
            }
            

            if(items) {

                $.each(items, function (key, obj) {
                    if ('id' in obj) {
                        initialItems.push(obj.id);
                    }
                });

                extendedReseacherOptions.items = initialItems;
                extendedReseacherOptions.options = items;
            }


            (new UIBindings).selectize($(this), $.extend({}, extendedReseacherOptions, elOptions) )
        });


        var categorySelects = forms.find('[data-selectize-category]');
        var catSelectOptions = {
            copyClassesToDropdown: false,
            persist: false,
            maxItems: null,
            closeAfterSelect: true,
            onInitialize: function () {
                $(this.$wrapper).addClass('single');
            },
            plugins: ['remove_button']
        };

        categorySelects.each(function () {

            var organisationSelectize = $(this);
            var items = organisationSelectize.data('selectizeCategory');
            var initialItems = [];
            var maxItems = organisationSelectize.data('selectizeMaxItems');
            var elOptions = {};

            if(items) {

                $.each(items, function (key, obj) {
                    if ('id' in obj) {
                        initialItems.push(obj.id);
                    }
                });

                extendedSelectOptions.items = initialItems;
                extendedSelectOptions.options = items;
            }

            if (maxItems) {
                elOptions.maxItems = maxItems;
            }

            (new UIBindings).selectize($(this), $.extend({}, catSelectOptions, elOptions) );
        });


        // Event Listeners      

        forms.find('.wygwam textarea').each(function() {
            var element = $(this);
            var name = element.attr('name');

            var editor = CKEDITOR.instances[name];

            if (editor) {
                editor.on('change', function(evt) {
                    editor.updateElement();
                    console.log('trigger change');
                    element.trigger('change');
                });
            }
        });

        forms.find('#cf_general_alt_title').on('change keyup', function () {
            $('#title').val($(this).val());
        });

        forms.find("#member_firstname, #member_lastname").on('change keyup', function () {
            var firstName = $('#member_firstname').val();
            var lastName = $('#member_lastname').val();

            $('#member_alt_title , #title').val(firstName + ' ' + lastName);
        });

        forms.find('div[id$=checkboxes] [name="category[]"]').each(function () {
            var checkbox = $(this);
            var formGroup = checkbox.closest('.form-group');
            var hiddenInput = formGroup.find('.category-checker');
            var checkboxes = formGroup.find('[name="category[]"]');
            var values = [];

            checkboxes.each(function () {
                if ($(this).prop('checked')) {
                    values.push($(this).val());
                }
            });

            hiddenInput.val(values.join(' '));

        });

        forms.on('change', '[name="category[]"]', function (e) {
            var checkbox = $(this);
            var formGroup = checkbox.closest('.form-group');
            var hiddenInput = formGroup.find('.category-checker');
            var checkboxes = formGroup.find('[name="category[]"]');
            var values = [];

            checkboxes.each(function () {
                if ($(this).prop('checked')) {
                    values.push($(this).val());
                }
            });

            hiddenInput.val(values.join(' '));
        });
        

        forms.find('[data-date-picker-range]').each(function () {
            var dateRange = $(this);
            var inputs = dateRange.find('[data-date-picker]');
            var format = 'mm/dd/yyyy';

            if (dateRange.data('dateFormat') !== undefined) {
                format = dateRange.data('dateFormat');
            }

            $(this).bootstrapDP({inputs: inputs, format: format, keyboardNavigation: false, defaultViewDate: {year: 2012, month: 2} });

            $.each(inputs, function(){
                var date = $(this).data('datePicker');
                $(this).bootstrapDP('update', date);
            })
        });


        forms.find('[data-date-picker]').each(function(){
            var dateEl = $(this);
            var format = 'mm/dd/yyyy';


            if (dateEl.data('dateFormat') !== undefined) {
                format = dateEl.data('dateFormat');
            }
            
            dateEl.bootstrapDP({
                format: format,
                autoclose: true,
                templates: {
                    leftArrow: '<i class="fa fa-angle-left"></i>',
                    rightArrow: '<i class="fa fa-angle-right"></i>'
                }
            })
        })
        .on('changeDate', function(e) {

            var fieldElement = $(e.target);
            var updateField = fieldElement.data('updateField');
            var updateFieldElement = $('[name="'+ updateField +'"]');

            if (updateFieldElement.exists()) {

                if (updateFieldElement.attr('id') === 'entry') {
                    updateFieldElement.val(e.format() + ' 12:00 AM');

                } else {
                    updateFieldElement.val(e.format() + ' 11:59 PM');
                }

                console.log(updateFieldElement.val());
            } 
        })
        .each(function(){
            var el = $(this);
            var val = el.data('datePicker');

            if (val) {
                val = val.split(' ');        
                el.val(val[0]);
            }
        });


        forms.on('change', '[data-field-toggle] input[type="radio"]', function () {
            var fieldToggle = $(this).closest('[data-field-toggle]');
            var val = this.value;

            var curfieldToggle = fieldToggle.find('[data-toggle-field]').addClass('hide').filter(function () {
                return $(this).data('toggleField') === val;
            }).removeClass('hide');
        });

        forms.on('change', '[data-toggle-input]', function () {
            var el = $('option:selected', this);
            var parent = el.closest('.matrix-block');
            parent.find('[data-show]').addClass('hide');
            parent.find('[data-show="' + el.data('link') + '"]').removeClass('hide');
        });

        forms.on('click', '[data-remove="exists"]', function () {
            var block = $(this).closest('.matrix-block');
            return false;
        });

        forms.on('change','.selectized, input[type="radio"], textarea', function () {
            var group = $(this).closest('[data-parsley-group]').data('parsleyGroup');

            var $parsleyForm = $(this).closest('form').parsley();

            if (group) {
                console.log(this.value, 'change', group);
                $parsleyForm.validate({group: group, force: true});
            }
        });


        forms.on('change', 'input[type="file"]', function (evt) {

            var input = $(this);
            var str = this.value;
            var strIndexOf = str.replace(/\\/g, '/');
            var filename = strIndexOf.split("/").pop();
            var parent = input.closest('[data-provides]');
            var imagePreview = parent.find('[data-update-image]');

            parent.addClass(this.value ? 'fileinput-exists' : 'fileinput-new').removeClass(this.value ? 'fileinput-new' : 'fileinput-exists');
            parent.find('.fileinput-filename').text(filename);
            parent.find('[data-update-hidden]').val(filename);
            parent.find('[data-update-checkbox]').prop("checked", true);
            parent.find('[data-update-remove]').val('');
            // readURL(this, imagePreview);

            var $parsleyForm = $(this).closest('form').parsley();
            var group = $(this).closest('[data-parsley-group]').data('parsleyGroup');
            console.log(this.value, 'change', group);

            if (group) {
                $parsleyForm.validate({group: group, force: true});
            }

        });

        forms.on('click', '[data-dismiss]', function (evt) {
            var el = $(this);
            var parentId = el.data('dismiss');
            var parent = el.closest('[data-provides]');
            var group = parent.find('input[type="file"]').closest('[data-parsley-group]').data('parsleyGroup');
            parent.removeClass('fileinput-exists').addClass('fileinput-new');
            parent.find('input[type="file"]').val('').trigger('change');
            
            console.log(parent.find( 'file remove', 'input[type="file"]').val());
            return false;
        });


        return this;
    };

    function readURL(input, image) {

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                image.attr('src', e.target.result);
            };

            reader.readAsDataURL(input.files[0]);
        }
    }
}

function YouTubeIframeAPI(selector) {
    var ytOptions = {
        height: '390',
        width: '640',
        playerVars: {
            rel: 0,
            showinfo: 0,
            modestbranding: 0,
            autohide: 1,
            controls: 2,
            vq: 'hd720',
            disablekb: 0,
            autoplay: 0,
            loop: 0,
            playsinline: 1,
            cc_load_policy: 1,
            fs: 1,
            enablejsapi: 1,
            origin: location.protocol + '://www.' + location.hostname
        },
        videoId: '',
        events: {
            'onStateChange': videoOnStateChange
        }
    };

    var elements = $(selector);


    function bindPlayer(playButton) {
        $(playButton).on('click', function (evt) {
            evt.preventDefault();
            var player = $(this);
            player.data('player').playVideo();
            player.addClass('playing');
        });
    }

    function videoOnStateChange(event) {
        var _player, _videoObject, _videoId, slider, slickObj;
        _player = event.target;
        _videoId = $(event.target.f).attr('id');
        slider = $(event.target.f).closest('.slick-slideshow');

        switch (event.data) {
            case 0:
            case -1:
            record('video ended');
            videoPlay('resume');
            break;
            case 1:
            case 3:
            record('video playing from ' + _player.getCurrentTime());
            videoPlay('pause');
            break;
            case 2:
            record('video paused at ' + _player.getCurrentTime());
            videoPlay('resume');
        }

        function record(str) {
            console.log(str);
        }

        function videoPlay(action) {

            slickObj = slider.slick('getSlick');

            if (!slider.exists()) {
                return;
            }

            if (action === 'pause') {
                slider.slick('slickPause');
            } else {
                slider.slick('slickPlay');
            }
        }
    }

    this.loadAPI = function () {
        
        if (elements.exists()) {
            var tag = document.createElement('script');
            tag.src = "//www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        return this;
    };

    this.build = function(options){
        var  element, player, id, playButton;
        
            elements.each(function () {
                element = $(this);
                id = element.data('youtube');
                ytOptions.videoId = id;
                ytOptions.playerVars.cc_lang_pref = element.data('lang');
                playButton = $('[data-video="' + id + '"].player');


                if (playButton.data('player') === undefined) {
                    player = new YT.Player(this.id, ytOptions);
                    playButton.data('player', player);
                }

                bindPlayer(playButton);

            });

        return this;
    };
}

function VimeoIframeAPI(selector) {
    var elements = $(selector);
    this.build = function(){
         
         var element, player, id, playButton, playerId;

         elements.each(function () {
             element = $(this);
             id = element.data('vimeo');
             playerId = 'player' + id;
             
             playButton = $('[data-video="' + id + '"].player');

             if (playButton.data('player') === undefined) {
                 player = new Vimeo.Player(playerId, {id: id});
                 playButton.data('player', player);
             }

             bindPlayer(playButton);

         });

    };

    function bindPlayer(playButton){

        $(playButton).on('click', function (evt) {
            evt.preventDefault();
            var player = $(this);
            player.data('player').play().then(function(){
                 player.addClass('playing');
            });
            
        });
    }
}

function GoogleMap(selector) {
    var mapId = selector;
    var _mapId = '#' + selector;
    var mapMarkers = $('[data-map-marker]');
    var centerLatLng; 
    var zoom = 8;
    var prevInfoWindow = null;

    this.init = function(){
        console.log(mapMarkers.length);
        if ($(_mapId).exists() && mapMarkers.exists()) {
            console.log('build map');
            $('[data-tab-map]').removeClass('hide');

            centerLatLng = mapMarkers.length > 1 ? new google.maps.LatLng(-29.081318, 26.196943) : new google.maps.LatLng(mapMarkers.data('lat'), mapMarkers.data('lng'));
            if (mapMarkers.length > 1) {
                zoom = 5;
            } 

            var map = new google.maps.Map(document.getElementById(mapId), {
                zoom: zoom,
                center: centerLatLng,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            $.each(mapMarkers, function () {
                var marker = {
                        type: 'info'
                    },
                    $marker = $(this),
                    info = $marker.data(),
                    content = $marker.html();

                marker.position = new google.maps.LatLng(info.lat, info.lng);
                marker.title = info.title;
                marker.content = content;

                addMarker(marker, map);
            });            
        }
    }

    function addMarker(feature, map) {
        var marker = new google.maps.Marker({
            position: feature.position,
            map: map,
            title: feature.title
        });

        var infoWindow = new google.maps.InfoWindow({
            content: feature.content
        });

        marker.addListener('click', function () {
            if (prevInfoWindow) {
                prevInfoWindow.close();
            }

            prevInfoWindow = infoWindow;

            infoWindow.open(map, marker);
        });
    }
}

$(function () {
    'use strict';
    (new UIBindings).bindMagnificPopup();

    (new UIBindings).bindEvents();
    (new UIBindings).bindSliders();
    (new UIBindings).bindSharing();
    (new UIBindings).bindToc();
    (new ExternalLinkHandler).addTargetAttribute($('body'));
    (new PageTimer).logToConsole();

    (new YouTubeIframeAPI('[data-youtube]')).loadAPI();
    (new VimeoIframeAPI('[data-vimeo]')).build();
    (new formBindings('form', {})).addValidators().validate().bindEvents();

    (new StickyFooter($('#container'), $('#footer'))).init().bindOnResize();


      $('[data-toggle="offcanvas"]').click(function () {
        $('.row-offcanvas').toggleClass('active')
      });

});